header.Header h1 {
  color: #b8ca92;
  -webkit-text-stroke-color: #9fae7e;
  -webkit-text-stroke-width: 2px;
  text-align: center;
  font-size: 62px;
}

.List-back {
  position: absolute;
  font-size: 16px;
  top: 30px;
  left: 15px;
}

@media (max-width: 801px) {
  header.Header h1 {
    font-size: 42px;
  }

  .List-back {
    font-size: 15px;
    top: 17px;
    left: 10px;
  }
}

@media (max-width: 600px) {
  header.Header h1 {
    font-size: 32px;
  }

  .List-back {
    font-size: 13px;
    top: 10px;
    left: 5px;
  }
}
