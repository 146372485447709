.List {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Row {
  width: 231px;
  height: 231px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  margin: 22px;
  cursor: pointer;
  flex-shrink: 0;

  transition: background-color 0.1s ease, border 0.1s ease, box-shadow 0.1s ease;
}

.Row:active,
.Row:hover {
  background-color: #828489;
  border: 1px solid #b9bbc1;
  box-shadow: inset 3px 3px 4px rgba(0, 0, 0, 0.25),
    inset -3px -3px 4px rgba(0, 0, 0, 0.25);
}

.Row h1 {
  color: #444649;
}

.Home-filter {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.Home-filter > input {
  width: 100%;
  padding: 15px 10px 10px;
  max-width: 600px;
  font-size: 24px;
  border: 2px solid hsla(214, 10%, 87%, 100%);
  background-color: hsla(0, 100%, 99%, 100%);
  box-shadow: 3px 3px 10px 1px rgba(0, 0, 0, 0.25), -3px -3px 10px 1px #FFFFFF;
  color: hsla(214, 3%, 45%, 100%);
  outline: none;
  border-radius: 13px;
}

.Home-filter > input::placeholder {
  color: hsla(214, 10%, 87%, 100%);
  font-size: 24px;
}


@media (max-width: 600px) {
  .Row {
    width: 100%;
    height: 100px;
    margin: 0;
  }
}
