:root {
  --header-height: brown;
}

h1 {
  margin: 0;
}

.App {
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to bottom right, #dadde1, #acaeb4);
  font-family: NanumGothic;
}

.App .Data {
  overflow: auto;
}

td {
  border-bottom: 1px solid black;
  border-right: 1px solid black;
  user-select: none;
  text-align: center;
}

td.B {
  background-color: red;
  color: white;
}

td.G {
  background-color: green;
  color: white;
}

td.Disabled {
  background-color: black;
}

table {
  border-spacing: 0;
  overflow: auto;
}

thead tr th {
  height: 140px;
  white-space: nowrap;
  padding: 0;
}

thead tr th div {
  transform: translate(14px, 51px) rotate(315deg);
  width: 30px;
}

thead tr th div span,
tbody tr th {
  border-bottom: 1px solid #ccc;
}

thead tr th div span {
  padding: 5px 10px;
}

/* transform: rotate(270deg); */
