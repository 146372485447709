.Plant > h2 {
  text-align: center;
  font-weight: 300;
  font-size: 24px;
}

.Plant-List {
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Plant-Row {
  width: 231px;
  height: 231px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid transparent;
  margin: 22px;
  flex-shrink: 0;
}

.Plant-Row h1 {
  color: #444649;
}

.Plant-Row div {
  height: 30px;
  width: 30px;
  margin-top: 10px;
  border-radius: 50%;
  background-color: #444649;
}

.Plant-Row div.good {
  background-color: green;
}

.Plant-Row div.bad {
  background-color: red;
}

@media (max-width: 801px) {
  .Plant > h2 {
    font-size: 18px;
    margin: 17px 0;
  }
}

@media (max-width: 600px) {
  .Plant > h2 {
    font-size: 16px;
    margin: 14px 0;
  }

  .Plant-Row {
    width: 100%;
    height: 100px;
    margin: 0;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 25px;
    box-sizing: border-box;
    border-bottom: 1px solid grey;
  }
}
